import React  from "react";
import "./CMmaincontent.css";
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';

const CMmaincontent = () => {


    return (
      <ParallaxProvider>
        <div className="responsive-c-m">
          <br />

          <div className="c-m-pc">
            <ParallaxBanner style={{ aspectRatio: "2 / 1", width: "100%" }}>
              <ParallaxBannerLayer
                image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/contract-manufacturing/tile-01-a.jpg"
                speed={7}
              />
            </ParallaxBanner>
          </div>
          <div className="c-m-mobile">
            <ParallaxBanner style={{ aspectRatio: "auto 360 / 540" }}>
              <ParallaxBannerLayer
                image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/contract-manufacturing/tile-01-a-II.jpg"
                speed={10}
              />
            </ParallaxBanner>
          </div>

          <div className="first-para-c-m">
            <p className="para">
              These entities execute contracted manufacturing of world-renowned
              homecare products, particularly in the household cleaning, liquid
              hand wash, air fresheners, and shoe polish segments. Housed in
              world-class facilities, our operations adhere to stringent global
              quality standards whilst fulfilling the ever-increasing demand of
              these FMCG products. As the sole manufacturer of these products
              for Sri Lanka, the continued outstanding performance of our
              dedicated teams have helped preserve our manufacturing
              partnerships over nearly two decades
              <br />
              <br />
              Lalan CM Manufacturing (Private) Limited <br />
              LSCJ Manufacturing (Private) Limited <br />
              LDLS Manufacturing (Private) Limited <br />
            </p>
          </div>

          <div className="first-para-c-m">
            <p className="address">
              Contract Manufacturing <br />
              Lalan Warana Complex, <br />
              Gorakadeniya Road, <br />
              Uduthuththiripitiya, <br />
              Yakkala, <br />
              Sri Lanka. <br />
              (+94) 71 456 5610 <br />
              info.cm@lalangroup.com <br />
              <br />
            </p>
          </div>

          <div>
            <div className="c-m-pc">
              <center>
                <h1 className="bigHugeTitle">
                  9 Sectors <br />
                  of Integrated <br />
                  <span
                    style={{
                      color: "#46708a",
                      fontStyle: "italic",
                      fontWeight: "bold",
                    }}
                  >
                    Expertise
                  </span>
                </h1>
                <div style={{ marginTop: "100px", marginBottom: "200px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "200px",
                    }}
                  >
                    <div style={{ width: "2px", background: "#46708a" }}></div>
                  </div>
                </div>
              </center>
            </div>

            <div
              className="c-m-mobile"
              style={{ marginTop: "200px", marginBottom: "300px" }}
            >
              <p className="text-max-c-m">
                9 Sectors of Integrated
                <span
                  style={{
                    color: "#46708a",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Expertise
                </span>
              </p>
            </div>
          </div>

          <div></div>
        </div>
      </ParallaxProvider>
    );
    }
    
    export default CMmaincontent;